export default [
    {
        id: 1,
        link: '/',
        title: 'Main'
    },
    {
        desc: 'Explore the four key features of Traqq in greater detail',
        id: 2,
        link: '',
        subnav: [
            {
                desc: 'Track the work hours of your employees accurately.',
                icon: 'timer',
                id: 1,
                link: '/time-tracking',
                title: 'Time tracking'
            },
            {
                desc: 'Monitor employee activities from various angles.',
                icon: 'monitoring',
                id: 2,
                link: '/employee-monitoring',
                title: 'Employee monitoring'
            },
            {
                desc: 'Manage admins, managers, and users effortlessly.',
                icon: 'settings',
                id: 3,
                link: '/team-management',
                title: 'Team management'
            },
            {
                desc: 'Automatically create sophisticated team reports.',
                icon: 'graph',
                id: 4,
                link: '/team-reporting',
                title: 'Team analysis and reporting'
            }
        ],
        title: 'Features'
    },
    {
        desc: 'Whether you are a large organization with many employees, or a freelancer looking to track work hours, Traqq is for you!',
        id: 3,
        link: '',
        subnav: [
            {
                desc: 'Track the work hours of everyone in your company.',
                icon: 'enterprises',
                id: 1,
                link: `${ process.env.NEXT_PUBLIC_APP_URL }/product/enterprise-time-tracking-software/`,
                title: 'Enterprises'
            },
            {
                desc: 'Businesses of any size use and benefit from Traqq.',
                icon: 'businesses',
                id: 2,
                link: `${ process.env.NEXT_PUBLIC_APP_URL }/product/time-tracking-app-for-small-businesses/`,
                title: 'Businesses'
            },
            {
                desc: 'Traqq is great for agencies with remote employees.',
                icon: 'atom',
                id: 3,
                link: '/time-tracking-app-for-agencies',
                title: 'Agencies'
            },
            {
                desc: 'Teams big or small can track time and create reports.',
                icon: 'teams',
                id: 4,
                link: '/time-tracking-app-for-teams',
                title: 'Teams'
            },
            {
                desc: 'Traqq’s free plan is the best for tracking freelancer work.',
                icon: 'freelancers',
                id: 5,
                link: `${ process.env.NEXT_PUBLIC_APP_URL }/product/time-tracking-software-for-freelancers/`,
                title: 'Freelancers'
            }
        ],
        title: 'Use Cases'
    },
    {
        id: 4,
        link: '/pricing',
        title: 'Pricing'
    },
    {
        id: 5,
        link: '/support',
        title: 'Help Center'
    },
    {
        desc: 'Learn about Traqq or explore our blog to gain valuable knowledge in employee management, freelancing, productivity and so much more. Got a question? Drop us a message',
        id: 6,
        link: '',
        subnav: [
            {
                desc: 'We are a team of bright, driven, and passionate people working hard to provide you with the best time tracker.',
                icon: 'about-us',
                id: 1,
                link: '/about',
                title: 'About Us'
            },
            {
                desc: 'Got a question? Drop us an email or fill out the form.',
                icon: 'email',
                id: 3,
                link: '/contact',
                title: 'Contact Us'
            }
        ],
        title: 'Company'
    },
    {
        desc: 'Explore our blog to find the coolest productivity tips and hottest news in the industry.',
        icon: 'blog',
        id: 7,
        link: `${ process.env.NEXT_PUBLIC_BLOG_URL }/`,
        title: 'Blog'
    }
];
